import React, { useState, useEffect } from 'react';
import { format, isBefore, subDays } from 'date-fns';
import { fetchCreativeById } from '../services/api';
import { MdBrokenImage } from "react-icons/md";
import ErrorMessage from './ui/ErrorMessage';
import Spinner from './ui/Spinner';

const AdTable = ({
  ads,
  filters,
  handleFilterChange,
  requestSort,
  getSortIcon,
  currentPage,
  setCurrentPage,
  adsPerPage
}) => {
  console.log('Received ads in AdTable:', ads);
  const [creatives, setCreatives] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState({});

  const indexOfLastAd = currentPage * adsPerPage;
  const indexOfFirstAd = indexOfLastAd - adsPerPage;
  const currentAds = ads.slice(indexOfFirstAd, indexOfLastAd);
  console.log('Current ads:', currentAds);

  useEffect(() => {
    const loadCreatives = async () => {
      setIsLoading(true);
      setError(null);
      
      const adIdsToFetch = currentAds
        .filter(ad => !creatives[ad.ad_id] && !isLoaded[ad.ad_id]);
      
      if (adIdsToFetch.length === 0) {
        setIsLoading(false);
        return;
      }

      try {
        const newCreatives = {};
        const newIsLoaded = {};
        for (const ad of adIdsToFetch) {
          const creative = await fetchCreativeById(ad.advertiser_id, ad.ad_id);
          newCreatives[ad.ad_id] = creative;
          newIsLoaded[ad.ad_id] = true;
        }
        setCreatives(prevCreatives => ({ ...prevCreatives, ...newCreatives }));
        setIsLoaded(prevIsLoaded => ({ ...prevIsLoaded, ...newIsLoaded }));
      } catch (error) {
        console.error('Error fetching creatives:', error);
        setError('Failed to load creative data');
      }
      
      setIsLoading(false);
    };

    if (currentAds.length > 0) {
      loadCreatives();
    }
  }, [currentAds]);

  const renderCreative = (ad) => {
    const creative = creatives[ad.ad_id];

    if (!creative) {
      return <Spinner />;
    }

    if (creative.error) {
      return <ErrorMessage message={creative.error} />;
    }

    if (!creative.image_urls || creative.image_urls.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <MdBrokenImage className="text-lg text-secondary mb-2" />
          <p className="text-sm text-text_dark">Not available</p>
        </div>
      );
    }

    return (
      <div className="w-full h-full flex items-center justify-center">
        <div dangerouslySetInnerHTML={{ __html: creative.image_urls[0] }} />
      </div>
    );
  };

  const isAdActive = (lastDisplayedDate) => {
    const yesterday = subDays(new Date(), 1);
    return !isBefore(new Date(lastDisplayedDate), yesterday);
  };

  return (
    <div className="overflow-x-auto">
      <div className="mb-6">
        <h4 className="text-lg font-semibold mb-3 text-text_light">Filters:</h4>
        <div className="flex flex-wrap gap-4">
          <select
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
            className="border border-secondary rounded p-2 text-text_dark bg-white focus:outline-none"
          >
            <option value="all">All Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
          <input
            type="date"
            name="firstShowed"
            value={filters.firstShowed}
            onChange={handleFilterChange}
            className="border border-secondary rounded p-2 text-text_dark bg-white focus:outline-none"
          />
          <input
            type="date"
            name="lastShowed"
            value={filters.lastShowed}
            onChange={handleFilterChange}
            className="border border-secondary rounded p-2 text-text_dark bg-white focus:outline-none"
          />
          <input
            type="number"
            name="campaignDuration"
            value={filters.campaignDuration}
            onChange={handleFilterChange}
            className="border border-secondary rounded p-2 text-text_dark bg-white focus:outline-none"
            placeholder="Min Campaign Duration"
          />
        </div>
      </div>
      
      <div className="mb-4 text-text_light">
        <p>Showing {currentAds.length} of {ads.length} ads</p>
      </div>

      <table className="w-full text-sm text-left text-text_dark" >
        <thead className="text-xs uppercase text-text_dark">
          <tr>
            <th scope="col" className="w-3/8 px-6 py-3 cursor-pointer bg-secondary  text-text_light rounded-tl">
              Ad
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary text-text_light">
              Status
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary text-text_light">
              Ad Format
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary text-text_light" onClick={() => requestSort('start_date')}>
              First Showed {getSortIcon('start_date')}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary text-text_light" onClick={() => requestSort('end_date')}>
              Last Showed {getSortIcon('end_date')}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary text-text_light" onClick={() => requestSort('campaign_duration')}>
              Campaign Duration {getSortIcon('campaign_duration')}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary text-text_light">
              Landing Page
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary text-text_light rounded-tr">
              Advertiser
            </th>
          </tr>
        </thead>
        <tbody>
          {currentAds.map((ad, index) => (
            <tr key={ad.ad_id} className="bg-white hover:bg-gray-300">
              <td className="w-3/8 px-6 py-4 h-48">{renderCreative(ad)}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  isAdActive(ad.last_showed) ? 'bg-green-100 text-success' : 'bg-red-100 text-error'
                }`}>
                  {isAdActive(ad.last_showed) ? 'Active' : 'Not Active'}
                </span>
              </td>
              <td className="px-6 py-4">{ad.ad_format || 'N/A'}</td>
              <td className="px-6 py-4">
                {ad.first_showed
                  ? format(new Date(ad.first_showed), 'PPP')
                  : 'N/A'}
              </td>
              <td className="px-6 py-4">
                {ad.last_showed
                  ? format(new Date(ad.last_showed), 'PPP')
                  : 'N/A'}
              </td>
              <td className="px-6 py-4">{ad.campaign_duration ? `${ad.campaign_duration} days` : 'N/A'}</td>
              <td className="px-6 py-4">
                {ad.destination_landing ? (
                  <a href={`https://${ad.destination_landing}`} target="_blank" rel="noreferrer nofollow" className='hover:underline'>
                    {ad.destination_landing}
                  </a>
                ) : 'N/A'}
              </td>
              <td className="px-6 py-4">{ad.advertiser_name || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center mt-4 gap-1">
        {Array.from({ length: Math.ceil(ads.length / adsPerPage) }, (_, i) => (
          <button
            key={i}
            onClick={() => setCurrentPage(i + 1)}
            className={`mb-1 px-3 py-1 rounded ${
              currentPage === i + 1 ? 'bg-primary text-white' : 'bg-white text-text_dark hover:bg-gray-100'
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AdTable;
