import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { Card } from './ui/Card';
import CTAButton from './ui/CTAButton';

const PricingTier = ({ name, price, discountedPrice, features, isPopular }) => (
  <div className="relative h-full">
    {isPopular && (
      <span className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-primary text-text_light px-3 py-1 rounded-full text-sm font-semibold z-10">
        Most Popular
      </span>
    )}
    <Card className={`flex flex-col p-6 h-auto md:h-full md:min-h-[400px] ${isPopular ? 'border-primary' : 'border-border'}`} >
      <h3 className="text-2xl font-bold mb-2">{name}</h3>
      <div className="mb-4 flex flex-col items-start">
        {discountedPrice ? (
          <>
            <span className="text-xl font-bold line-through text-text_dark mb-1">${price.toFixed(2)}</span>
            <div className="flex items-baseline">
              <span className="text-4xl font-bold">${discountedPrice.toFixed(2)}</span>
              <span className="text-secondary ml-1">/month</span>
            </div>
          </>
        ) : (
          <div className="flex items-baseline">
            <span className="text-4xl font-bold">${price.toFixed(2)}</span>
            <span className="text-secondary ml-1">/month</span>
          </div>
        )}
      </div>
      <ul className="mb-6 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start mb-2">
            <FaCheck className="text-primary mr-2 flex-shrink-0 mt-1" />
            <span className="text-sm text-text_dark">{feature}</span>
          </li>
        ))}
      </ul>
      <CTAButton variant={isPopular ? 'primary' : 'secondary'} className="w-full mt-auto">
        Get Started
      </CTAButton>
    </Card>
  </div>
);

const PricingSection = () => {
  const pricingTiers = [
    {
      name: 'Free',
      price: 0.00,
      features: [
        'Up to 3 competitor domains',
      ],
      isPopular: false,
    },
    {
      name: 'Basic',
      price: 19.99,
      discountedPrice: 9.99,
      features: [
        'Up to 10 competitor domains',
        'Weekly ad reports',
        'Analytics',
      ],
      isPopular: false,
    },
    {
      name: 'Pro',
      price: 89.99,
      discountedPrice: 49.99,
      features: [
        'Up to 100 competitor domains',
        'Weekly and Monthly ad reports',
        'Analytics',
        'Email notifications',
      ],
      isPopular: true,
    },
    {
      name: 'Enterprise',
      price: 1499.99,
      features: [
        'Unlimited competitor domains',
        'Real-time ad tracking',
        'Custom reporting',
        'API access',
        'Dedicated support',
      ],
      isPopular: false,
    },
  ];

  return (
    <section className="py-16 bg-background">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-text_light text-center mb-12">Choose Your Plan</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        {pricingTiers.map((tier, index) => (
            <PricingTier key={index} {...tier} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
