import React from 'react';
import { FaStar, FaBolt, FaSearch } from 'react-icons/fa';
import CompetitorFinder from './CompetitorFinder';
import icon_rating from '../assets/icon_rating.png';
import CTAButton from './ui/CTAButton';
import { Link } from 'react-scroll';

const LandingFirstSection = () => {
  return (
    <section className="flex justify-center w-full mt-16 sm:mt-24 md:mt-32">
      <div className="flex flex-col items-center sm:items-start sm:flex-row justify-between w-full max-w-7xl px-4 gap-12">
        <div className="w-full sm:w-1/2 sm:pr-4 mb-12 sm:mb-0">          
          <div className="space-y-6 sm:space-y-8">
            <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-[530] leading-[112%] tracking-tight text-text_light">
              <div className="mb-4">
                Unveil competitor ads 
                <div className="rounded-lg md:rounded-xl border-2 rotate-3 mx-1 bg-white shadow-lg w-6 h-6 sm:w-10 sm:h-10 md:h-12 md:w-12 border-secondary inline-flex items-center justify-center">
                  <FaSearch className="w-3 h-3 sm:w-5 sm:h-5 md:w-7 md:h-7 text-secondary" />
                </div>
              </div>
              <div>
                <div className="rounded-lg md:rounded-xl border-2 -rotate-2 mx-1 bg-white shadow-lg w-6 h-6 sm:w-10 sm:h-10 md:h-12 md:w-12 border-secondary inline-flex items-center justify-center">
                  <FaBolt className="w-3 h-3 sm:w-5 sm:h-5 md:w-7 md:h-7 text-secondary" />
                </div>
                 Fast. Simple. Powerful.
              </div>
            </div>
            <div className="space-y-4">
              <p className="text-sm sm:text-base md:text-xl leading-[150%] text-text_light tracking-tight">
                Decode strategies, outpace the market, and turn competitor insights into your advantage.
              </p>
            </div>
            <div className="flex items-start ml-2">
              <div className="flex mr-2 mb-2">
                {[...Array(5)].map((_, index) => (
                  <FaStar key={index} className="text-accent mr-0.5 text-xs sm:text-base" />
                ))}
              </div>
              <span className="text-xs sm:text-sm text-text_light">
                4.7/5  |  based on +6,3k users
              </span>
            </div>
            <CTAButton 
              className="w-full sm:w-auto"
              as={Link}
              to="pricing"
              smooth={true}
              duration={500}
            >
              Get started for free
            </CTAButton>
          </div>
        </div>
        <div className="w-full sm:w-1/2 sm:pl-4">
          <CompetitorFinder />
        </div>
      </div>
    </section>
  );
};

export default LandingFirstSection;