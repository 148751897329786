import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google'; 
import { googleLogin } from '../services/users/users'; 
import googleIcon from '../assets/google_icon.png';
import ErrorMessage from '../components/ui/ErrorMessage';
import { Card, CardHeader, CardContent, CardTitle } from '../components/ui/Card';
import { FaUnlock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

function AuthPage() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleGoogleLogin = async (credential) => {
    setIsLoading(true);
    try {
      const data = await googleLogin(credential);
      
      // Store tokens
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
  
      // Store user data
      const userData = {
        email: data.email,
        name: data.name,
        surname: data.surname,
        profile_picture: data.profile_picture
      };
      localStorage.setItem('user', JSON.stringify(userData));
  
      // Navigate to the competitors page
      navigate('/competitors');
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onGoogleSignIn = (response) => {
    const token = response.credential;
    handleGoogleLogin(token);
  };

  return (
    <div className="flex flex-col items-center justify-center bg-background min-h-screen relative overflow-hidden">
      <Navbar />
      <div className="absolute inset-x-0 bottom-0 z-0 h-[calc(100%-4rem)]">
        {/* Center line */}
        <svg className="absolute w-full h-full" preserveAspectRatio="none">
          <path
            d="M0,80 C250,50 750,50 1000,20"
            fill="none"
            stroke="currentColor"
            className="text-primary"
            strokeWidth="2"
            strokeOpacity="0.6"
            strokeDasharray={Math.random() > 0.5 ? "5,5" : "none"}
          />
        </svg>
        
        {/* Random lines */}
        {[...Array(3)].map((_, index) => (
          <svg
            key={index}
            className="absolute w-full h-full"
            preserveAspectRatio="none"
          >
            <path
              d={`M0,${Math.random() * 100} C${Math.random() * 330},${500 + Math.random() * 500} ${330 + Math.random() * 340},${750 + Math.random() * 250} 1000,${Math.random() * 1000}`}
              fill="none"
              stroke="currentColor"
              className="text-primary"
              strokeWidth={Math.random() * 2 + 1}
              strokeOpacity={0.3 + Math.random() * 0.4}
              strokeDasharray={Math.random() > 0.5 ? "5,5" : "none"}
            />
          </svg>
        ))}
      </div>
      <div className="z-10 justify-center items-center text-center">
        <div className="rounded-lg md:rounded-xl border-2 -rotate-2 mx-1 bg-white shadow-lg w-6 h-6 sm:w-10 sm:h-10 md:h-12 md:w-12 border-secondary inline-flex items-center justify-center">
          <FaUnlock className="w-3 h-3 sm:w-5 sm:h-5 md:w-7 md:h-7 text-secondary" />
        </div>
        <Card className="mt-4 w-full max-w-md p-8 border border-border bg-white">
          <CardHeader>
            <CardTitle>Sign in to Close Scout</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col justify-center items-center align-middle">
            <p className="text-text_dark text-center mb-6">
              Discover all the ads that your competitors are running and gain valuable insights.
            </p>
            <hr className='w-1/5 border border-secondary'/>
            <br />
            <GoogleLogin
              onSuccess={onGoogleSignIn} 
              onFailure={(error) => {
                console.error('Login failed:', error);
                setError('An error occurred during Google login. Please try again.');
              }}
              style={{ width: '60%', margin: 'auto' }} // Adjust styling as needed
            >
              <button
                className="w-3/5 mx-auto flex items-center justify-center text-text_dark py-2 rounded bg-white border border-primary hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="animate-spin mr-2">&#8987;</span>
                ) : (
                  <img src={googleIcon} alt="Google" className="w-5 h-5 mr-2" />
                )}
                {isLoading ? 'Loading...' : 'Continue with Google'}
              </button>
            </GoogleLogin>
            {error && <ErrorMessage message={error} />}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default AuthPage;
